import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import AccountMenuContent from './MenuBar/AccountMenuContent';
import { X } from '@/public/icons/account/icons';

const AccountDrawer = ({
  setOpen,
  open,
}: {
  setOpen: (state: boolean) => void;
  open: boolean;
}) => (
  <Transition show={open} as={Fragment}>
    <Dialog
      onClose={() => setOpen(false)}
      className="relative z-50 lg:invisible"
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-50 bg-black/50" />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
      >
        <div className="fixed bottom-0 z-50 flex w-full items-end">
          <Dialog.Panel className="w-full bg-white">
            <Dialog.Title>
              <div className="flex items-center justify-between border-b border-gray-300">
                <h3 className="ml-5 text-base font-semibold">My account</h3>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="group p-5 focus:outline-none"
                >
                  <X className="h-5 w-5 transition duration-150 ease-in-out group-hover:scale-110" />
                </button>
              </div>
            </Dialog.Title>
            <AccountMenuContent
              closeModal={() => setOpen(false)}
              isMobile={true}
            />
          </Dialog.Panel>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
);

export default AccountDrawer;
