import Link from 'next/link';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import useAuth from '@/lib/use-auth';
import cartAccount from '@/public/icons/account/cart.svg';
import cartAccountFill from '@/public/icons/account/cart-fill.svg';
import homeAccount from '@/public/icons/account/home.svg';
import homeAccountFill from '@/public/icons/account/home-fill.svg';
import logoutAccount from '@/public/icons/account/logout.svg';
import claimAccount from '@/public/icons/account/claim.svg';
import claimAccountFill from '@/public/icons/account/claim-fill.svg';
import settings from '@/settings';

import i18n from '@/settings/i18n';

const t = i18n.init();

const AccountMenuContent = ({
  closeModal,
  isMobile,
}: {
  closeModal: () => void;
  isMobile?: boolean;
}) => {
  const auth = useAuth();
  const router = useRouter();
  const isOrderRoute =
    router.pathname.endsWith('/orders') ||
    router.pathname.endsWith('/return') ||
    router.pathname.endsWith('/warrantyClaim') ||
    router.pathname.endsWith('/help');
  const isAddressRoute = router.pathname.endsWith('/addresses');
  const isClaimRoute = router.pathname.endsWith('/claims');
  // Add US Store below to enable Claims Page feature in RT-4545 for US Store
  const isUSStore = settings.store === 'reebelo-dev';

  const btnClasses = `flex w-full items-center p-5 lg:hover:bg-gray-300 lg:py-3 focus:outline-none ${
    isMobile && 'focus:bg-gray-300'
  }`;

  const iconClass = 'h-5 w-5 lg:h-4 lg:w-4 xl:h-5 xl:w-5 flex items-center';

  return (
    <section
      className="flex flex-col items-start text-sm"
      id="e2e-account-dropdown"
    >
      <Link href="/account/orders">
        <button type="button" className={btnClasses} onClick={closeModal}>
          <div className={iconClass}>
            <Image
              height={20}
              width={20}
              alt="cartAccount"
              src={isOrderRoute ? cartAccountFill : cartAccount}
            />
          </div>
          <span className="ml-2">{t`My orders`}</span>
        </button>
      </Link>
      <Link href="/account/addresses">
        <button className={btnClasses} onClick={closeModal}>
          <div className={iconClass}>
            <Image
              height={17}
              width={17}
              alt="homeAccount"
              src={isAddressRoute ? homeAccountFill : homeAccount}
            />
          </div>
          <span className="ml-2">{t`My addresses`}</span>
        </button>
      </Link>
      {isUSStore && (
        <Link href="/account/claims">
          <button className={btnClasses} onClick={closeModal}>
            <div className={iconClass}>
              <Image
                height={17}
                width={17}
                alt="homeAccount"
                src={isClaimRoute ? claimAccountFill : claimAccount}
              />
            </div>
            <span className="ml-2">My Claims</span>
          </button>
        </Link>
      )}
      <button
        className={btnClasses}
        id="e2e-account-logout"
        onClick={async () => {
          await auth.logout();
          closeModal();
        }}
      >
        <div className={iconClass}>
          <Image
            height={20}
            width={20}
            alt="logoutAccount"
            src={logoutAccount}
          />
        </div>
        <span className="ml-2">Logout</span>
      </button>
    </section>
  );
};

export default AccountMenuContent;
